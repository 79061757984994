
//Controlador del módulo
function SupportSearchCtrl() {

    //*******************
    // Contrato externo
    //*******************

    var options = {

    };

    return {
        init: init
    };

    //*******************
    // Implementación
    //*******************
    function init() {
        bindEvents();
    }

    function bindEvents() {
        var btn = document.getElementById("search-button");
        var form = document.getElementById("search-form");

        if (btn && form){
            btn.addEventListener('click', search);
            form.addEventListener('submit', search);
        }
    }

    function search(event) {
        event.preventDefault();

        var element = google.search.cse.element.getElement('google-results');
        var query = document.getElementById("query").value; 

        var posts = document.getElementById('last-posts-wrapper');
        var results = document.getElementById('search-results-wrapper');

        if (query === '') {
            results.className = removeClass(results, 'animated fadeIn')
            results.style.display = 'none';

            posts.className=addClass(posts, 'animated fadeIn');
            posts.style.display = 'block';

        } else {
            results.className = addClass(results, 'animated fadeIn');
            results.style.display = 'block';

            posts.className = removeClass(posts, 'animated fadeIn');
            posts.style.display = 'none';

            element.execute('support ' + query);
        }
    }

  //Utilizada para reemplazar jquery.hasClass
    function hasClass(elem, className) {
      return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
    }

    //Utilizada para reemplazar jquery.addClass
    //retorna el string para la lista de clases
    function addClass(elem, className) {
        return !hasClass(elem,className)?(elem.className+' '+className):elem.className;
    }

    //Utilizada para reemplazar jquery.removeClass
    //retorna el string para la lista de clases
    function removeClass(elem, className) {
    var newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ') + ' ';
    if (hasClass(elem, className)) {
        while (newClass.indexOf(' ' + className + ' ') >= 0 ) {
            newClass = newClass.replace(' ' + className + ' ', ' ');
        }
    }
    return newClass.replace(/^\s+|\s+$/g, '');
    }

}

export default SupportSearchCtrl();



import reduce from 'lodash/reduce';

import $ from 'jquery';

/**
 * Módulo para manejar los envios de formularios a aurora
 *
 * Utiliza código similar al que se usa en los iforms para angular
 * https://gitlab.com/imolko/aurora/blob/master/aurora-web/src/main/webapp/iforms/js/iforms/iforms-controllers.js
 */
function IformCtrl() {
  //*******************
  // Contrato externo
  //*******************

  return {
    init: init,
    send: send
  };

  //*******************
  // Implementación
  //*******************
  /**
   * Inicialización del módulo
   */
  function init() { 
    console.log('Iform inicializado');
  }
  /**
   * Hace el envio del formulario a aurora
   *
   * El envio siempre se previene para evitar que se haga directamente
   *
   * Se pueden presentar dos tipos de errores:
   * * Errores en formato de aurora
   * * Errores al hacer el post
   *
   * En ambos casos se hace un form.submit para forzar
   * que los errores se muestren directamente en el iform
   * y nos evitamos tener que parsear los posibles errores 
   */
  function send(event) {
    /** Validamos que sea un evento válido */
    if (!event || !event.target || !event.target.action) {
      console.warn('Function only for handling onsubmit events');
      return;
    }
    /** Obtenemos el formulario del evento*/
    const form = event.target;
    /** Obtenemos el url del action del form */
    const url = event.target.action;
    /** Convertimos los campos en un json */
    const data = formToJson(event.target.elements);
    /** Buscamos el token de recaptcha */
    const recaptchaToken = window.IFORM_RECAPTCHA_TOKEN;
    /** Hacemos el envio */
    post(url, data, recaptchaToken)
      .done( (response, textStatus) => successHandler(response, textStatus, form))
      .fail( (jqXHR, textStatus, error) => failHandler(jqXHR, textStatus, error, form));
    /** Prevenimos que se envie el form */
    event.preventDefault();
  }
  /**
   * Convierte una lista de campos
   * en un mapa {[name: string]}: value
   */
  function formToJson(fields) {
    /** Reducimos los campos a un mapa */
    const result = reduce(fields, function(acum, field) {
      /** Evitamos agregar campos sin nombre (como el button) */
      if (field.name && field.name !== '') {
        acum[field.name] = field.value;  
      }
      return acum;
    }, {});
    return result;
  }
  /**
   * Hace la petición al servidor
   */
  function post(url, body, recaptchaToken) {
    /**
     * Encabezados para el post
     */
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control' : 'no-cache',
      'x-iform-recaptcha-token': recaptchaToken ? recaptchaToken : ''
    };
    /** Hacemos la petición al backend */
    return $.ajax({
      url: url,
      method: 'POST',
      contentType: 'application/json',
      headers: headers,
      data: JSON.stringify(body),
    });
  }
  /**
   * Maneja la respuesta exitosa al hacer post
   */
  function successHandler(response, textStatus, form) {
    console.log('SUCCESS 1 x-->', response, textStatus, form);
    /** No hay errores, hacemos la redireccion */
    if (!!response.confirmationUrl && response.confirmationUrl !== '') {
      console.log('Redireccionamos al confirmation Url', response.confirmationUrl);
      /** Redireccionamos al confirmacionUrl */
      window.location.href = response.confirmationUrl;
      return;
    }
    /** Verificamos si hay algún error en la respuesta */
    console.warn('No confirmationUrl-->', response.messageHead, response.messageBody);
    /** Enviamos el formulario para que se muestren los errores en línea */
    form.submit();
  }
  /**
   * Maneja la respuesta fallida al hacer post
   */
  function failHandler(jqXHR, textStatus, error, form) {
    /** Intentamos enviar el formulario directamente */
    form.submit();
  }
}
/**
 * Exportamos el controlador de Iform
 */
export default IformCtrl();






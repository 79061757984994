import merge from 'lodash/merge';
import forEach from 'lodash/forEach';

import 'waypoints.noframework';
import 'waypoints.inview';

import 'animate.css/animate.css';

function WaypointAnimationsCtrl() {

	//*******************
	// Contrato externo
	//*******************
	var _options = {


	};

	return {
		init: init
	};

	//*******************
	// Implementación
	//*******************
	function init(newOptions) {
		_options = merge(_options, newOptions);
		var points = document.querySelectorAll('.waypoint');
		forEach(points, createWaypoint);
	}

	function createWaypoint(element, index) {
		return new Waypoint.Inview({
			element: element,
			enter: function (direction) {
				return enterWaypoint(direction, element);
			},
			exited: function (direction) {
				return exitedWaypoint(direction, element);
			}
		});
	}

	function enterWaypoint(direction, element) {
		var animations=element.querySelectorAll('[data-animation]');
		forEach(animations, function(a){
			var effect = a.getAttribute("data-animation");
			a.className = addClass(a, 'animated ' + effect);
		});
	}

	function exitedWaypoint(direction, element) {
		var animations=element.querySelectorAll('[data-animation]');
		forEach(animations, function(a){
			var effect = a.getAttribute("data-animation");
			a.className = removeClass(a, 'animated ' + effect);
		});		
	}

  //Utilizada para reemplazar jquery.hasClass
	function hasClass(elem, className) {
	  return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
	}

	//Utilizada para reemplazar jquery.addClass
	//retorna el string para la lista de clases
	function addClass(elem, className) {
		return !hasClass(elem,className)?(elem.className+' '+className):elem.className;
	}

	//Utilizada para reemplazar jquery.removeClass
	//retorna el string para la lista de clases
	function removeClass(elem, className) {
    var newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ') + ' ';
    if (hasClass(elem, className)) {
        while (newClass.indexOf(' ' + className + ' ') >= 0 ) {
            newClass = newClass.replace(' ' + className + ' ', ' ');
        }
    }
    return newClass.replace(/^\s+|\s+$/g, '');
	}

}

export default WaypointAnimationsCtrl();

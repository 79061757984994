import waypoint from './modules/waypoint-animations';
import elasticHeader from './modules/elastic-header';
import search from './modules/search';
// Importamos la librería para iforms
import iform from './modules/iform.js';

// Inicializamos el waypoint.
waypoint.init();

//Inicializamos la elasticidad del toolbar
elasticHeader.init();

//Inicializamos el script para búsquedas
search.init();

// Inicializamos el módulo de iform
iform.init();
// Publicamos el objeto iform en el espacio global
// para que pueda ser utilizado desde los formularios como onsubmit
window.iform = iform;

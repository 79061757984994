import forEach from 'lodash/forEach';
import throttle from 'lodash/throttle';


function ElasticHeaderCtrl() {
	//*******************
	// Contrato externo
	//*******************

  //Cantidad de pixeles donde debe cambiarse la clase al header
  var SIZE_UMBRAL = 120;

  //Status actual de la barra (bottom|top)
  var _status='top';
  //Elemento del header
  var header;

	return {
		init: init
	};

	//*******************
	// Implementación
	//*******************
	function init(){
		bindEvents();
	}

	function bindEvents(){
		header=document.querySelectorAll("header");
		if (header && header.length>0){
			window.addEventListener("scroll", throttle(function(){verifyScroll(header[0]);}, 300));
		}
	}

  function verifyScroll(header) {
  	var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
  	if (scrollTop> SIZE_UMBRAL){
      if (_status!=='bottom'){
		header.className = addClass(header, "mini-bar");
        _status='bottom';        		
      }      
  	}else{
      if (_status!=='top'){
        header.className = removeClass(header, "mini-bar");
        _status='top';        		
      }          
  	}
  }

  //Utilizada para reemplazar jquery.hasClass
	function hasClass(elem, className) {
	  return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
	}

	//Utilizada para reemplazar jquery.addClass
	//retorna el string para la lista de clases
	function addClass(elem, className) {
		return !hasClass(elem,className)?(elem.className+' '+className):elem.className;
	}

	//Utilizada para reemplazar jquery.removeClass
	//retorna el string para la lista de clases
	function removeClass(elem, className) {
    var newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ') + ' ';
    if (hasClass(elem, className)) {
        while (newClass.indexOf(' ' + className + ' ') >= 0 ) {
            newClass = newClass.replace(' ' + className + ' ', ' ');
        }
    }
    return newClass.replace(/^\s+|\s+$/g, '');
	}
}


export default ElasticHeaderCtrl();
